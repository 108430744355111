.dancing-menu-item {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: 40;
    font-weight: 40;
    font-style: normal;
    font-size: x-large;
  }

.login-btn{
  font-family: "Dancing Script", cursive;
    font-optical-sizing: 40;
    font-weight: 40;
    font-style: normal;
    font-size: x-large;
}
